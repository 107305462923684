import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { PageLayout } from '../layouts';
import { Box, Typography, ButtonLink } from '../components';
import NotFoundSVG from '../../static/page_not_found.svg';
import { truncateStringSeoFriendly } from '../helpers/truncate-string';

const NotFoundPage = () => (
  <PageLayout
    description={truncateStringSeoFriendly(
      `Hoppla, da ist etwas schief gelaufen. SEITE NICHT GEFUNDEN! Die gesuchte Seite existiert leider nicht. Sie können eine unserer wundervollen Seiten dennoch besuchen`
    )}
    title="404 Seite nicht gefunden"
    keywords={['404', 'seite nicht gefunden', 'Stark Dynamics GmbH', 'Stark', 'Dynamics']}
    pathnames={['/404']}
  >
    <Box bgColorClass="contrastText" marginClass="marginBottom">
      <Container maxWidth="lg">
        <Grid container direction="column" justify="space-evenly" alignItems="center">
          <Grid item xs={11}>
            <img src={NotFoundSVG} alt="not found img" style={{ paddingTop: '5vh', paddingBottom: '5vh', width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="overline" style={{ fontSize: '1rem' }}>
              Hoppla, da ist etwas schief gelaufen.
            </Typography>
            <Typography variant="h6">SEITE NICHT GEFUNDEN!</Typography>
            <Typography variant="body1">Die gesuchte Seite existiert leider nicht. Sie können eine unserer wundervollen Seiten dennoch besuchen:</Typography>

            <Grid item xs={11} style={{ marginTop: 15 }}>
              <ButtonLink linkProps={{ to: '/produkte' }} buttonProps={{ variant: 'contained', color: 'secondary' }} style={{ marginRight: 10 }}>
                Produkte
              </ButtonLink>
              <ButtonLink linkProps={{ to: '/' }} buttonProps={{ variant: 'outlined', color: 'secondary' }}>
                Home
              </ButtonLink>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  </PageLayout>
);

export default NotFoundPage;
